// src/pages/Download.tsx

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import TermsAcceptance from '../components/TermsAcceptance';
import { useNavigate, Link } from 'react-router-dom';
import { NotificationContext } from '../contexts/NotificationContext';
import { jwtDecode } from "jwt-decode";
import { Helmet } from 'react-helmet-async';

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-section-1-bg);
  color: var(--color-text);
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 6rem auto 4rem;
  padding: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.div`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #ccc;
  text-align: center;

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
`;

/* New styled component to highlight NinjaTrader & RiskMaster version info */
const VersionHighlight = styled.div`
  background-color: #333;
  padding: 1rem 2rem;
  color: #ff6347;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const DownloadButtonStyled = styled.button`
  background-color: #FF4500;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background-color: #FF6347;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
`;

interface DownloadProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const Download: React.FC<DownloadProps> = ({ isLoggedIn, onLogout }) => {
  const [showTerms, setShowTerms] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);

  const isTokenValid = (token: string | null) => {
    if (!token) return false;
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp && decoded.exp > currentTime;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token && !isTokenValid(token)) {
      localStorage.removeItem('token');
      // showNotification('Session expired. Please log in again.');
      navigate('/');
      return;
    }

    if (token) {
      fetchUserData(token);
    }
  }, [navigate, showNotification]);

  const fetchUserData = async (token: string) => {
    try {
      const response = await fetch('https://riskmaster.pro/fetch_user_data_proxy.php', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        // showNotification('Session expired. Please log in again.');
        navigate('/');
        return;
      }

      const data = await response.json();
      if (data.status === 'success') {
        setUserData(data.user);
      } else {
        showNotification(data.message || 'Error fetching user data.');
      }
    } catch (error) {
      showNotification('Failed to fetch user data.');
      console.error(error);
    }
  };

  const handleDownloadClick = () => {
    const token = localStorage.getItem('token');

    if (token && !isTokenValid(token)) {
      localStorage.removeItem('token');
      // showNotification('Session expired. Please log in again.');
      navigate('/');
      return;
    }

    if (!token) {
      showNotification('Please login to download.');
      return;
    }

    if (userData) {
      setShowTerms(true);

      if (window.fbq) {
        window.fbq('track', 'DownloadAttempt');
      }
    }
  };

  const handleTermsAccepted = () => {
    setShowTerms(false);

    if (window.fbq) {
      window.fbq('track', 'DownloadInitiated');
    }

    window.location.href = 'https://drive.google.com/file/d/1aZXHp9XSwZiAUptZM_Ojj1HHbOKE7ppk/view?usp=sharing';
  };

  const handleExit = () => {
    setShowTerms(false);
  };

  return (
    <DownloadContainer>
      <Helmet>
        <title>Download Risk Master | RiskMaster</title>
        <meta
          name="description"
          content="Download the Risk Master software to enhance your trading strategy with automated risk management tools."
        />
        <link rel="canonical" href="https://www.riskmaster.pro/download" />
      </Helmet>
      <Navigation
        activeSection="download"
        handleScroll={() => {}}
        isLoggedIn={isLoggedIn}
        onLogout={onLogout}
      />
      <ContentWrapper>
        <Title>Download Risk Master</Title>
        <Description>
          <p>
            Download the latest version of Risk Master to start your journey towards consistent trading.
          </p>
          <p>
            Please make sure you meet the system requirements for NinjaTrader 8 and have read or seen the installation guide in the{' '}
            <Link to="/help-center" style={{ color: '#FF6347', textDecoration: 'underline' }}>
              Help Center
            </Link>.
          </p>
        </Description>

        {/* Highlight the recommended NinjaTrader version and RiskMaster version */}
        <VersionHighlight>
          We strongly recommend using the latest version of NinjaTrader (8.1.3.1 64-bit or higher). 
          <br />
          Current Risk Master version: <strong>1.1.1.0</strong>
        </VersionHighlight>

        <DownloadButtonStyled onClick={handleDownloadClick}>
          Download Now
        </DownloadButtonStyled>
      </ContentWrapper>
      {showTerms && userData && (
        <TermsAcceptance
          userId={userData.id}
          version="1.0"
          onAccepted={handleTermsAccepted}
          onExit={handleExit}
        />
      )}
      <Footer />
    </DownloadContainer>
  );
};

export default Download;
