import React from "react";

const RiskMasterAnnouncement: React.FC = () => {
  // Define styles before usage
  const styles: { [key: string]: React.CSSProperties } = {
    announcementContainer: {
      backgroundColor: "#1e1e1e",
      color: "#ffffff",
      padding: "25px",
      borderRadius: "10px",
      textAlign: "center" as const,
      fontSize: "20px",
      maxWidth: "780px", // Increased by 30%
      margin: "20px auto",
      boxShadow: "0 4px 10px rgba(255, 165, 0, 0.2)"
    },
    heading: {
      fontSize: "28px", // Increased font size
      marginBottom: "15px"
    },
    paragraph: {
      fontSize: "18px", // Increased font size
      marginBottom: "15px"
    },
    link: {
      color: "#ff8000", // Orange color instead of yellow
      fontWeight: "bold",
      textDecoration: "none",
      marginLeft: "5px"
    },
    linkHover: {
      textDecoration: "underline"
    }
  };

  return (
    <div style={styles.announcementContainer}>
      <h2 style={styles.heading}>RiskMaster is Going Free!</h2>
      <p style={styles.paragraph}>
        We are excited to announce that <strong>RiskMaster is available for free at itch.io</strong>.
        No subscriptions, no payments—just free access for everyone!
      </p>
      <p style={styles.paragraph}>  
        <a 
          href="https://aviramyagena.itch.io/riskmaster" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={styles.link}
        >
          Download Here
        </a>
      </p>
      <p style={styles.paragraph}>Thank you for your support—stay tuned for the release!</p>
    </div>
  );
};

export default RiskMasterAnnouncement;